<template>
  <form-group
    name="utm_source"
    type="hidden"
    :initial-value="route.query.utm_source"
  />

  <form-group
    name="utm_medium"
    type="hidden"
    :initial-value="route.query.utm_medium"
  />

  <form-group
    name="utm_campaign"
    type="hidden"
    :initial-value="route.query.utm_campaign"
  />
</template>

<script setup>
import { useRoute } from 'vue-router'

import FormGroup from '@shared/components/form/FormGroup.vue'

const route = useRoute()
</script>
