<template>
  <div
    v-if="success"
  >
    <app-heading class="mb-4">
      {{ t('auth.password_forgotten.success.title') }}
    </app-heading>

    <p class="text-sm mb-2">
      {{ t('auth.password_forgotten.success.email_1', { email: values.credential }) }}
    </p>

    <p class="text-sm mb-3">
      {{ t('auth.password_forgotten.success.email_2') }}
    </p>
  </div>

  <!--
    Use v-show instead of v-if,
    to not lose form values
  -->
  <form
    v-show="!success"
    novalidate
    @submit.prevent="handleSubmit"
  >
    <form-global-errors />

    <p class="text-xs mb-3">
      {{ t('auth.password_forgotten.intro') }}
    </p>

    <fieldset :disabled="formSubmitting">
      <form-group
        name="credential"
        rules="required|credential"
        type="email"
        label-as-placeholder
        :initial-value="credential"
        :form-control-props="{
          iconProps: { library: 'coolicon', name: 'mail' },
        }"
      />

      <app-button
        feature="confirm"
        type="submit"
        width="full"
        :label="t('auth.password_forgotten.submit')"
        :disabled="formSubmitting || invalid"
        :loading="formSubmitting"
      />
    </fieldset>
  </form>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useForm as useVVForm } from 'vee-validate'
import { useRoute } from 'vue-router'

import { sendPasswordResetLink } from '@shared/http/api'
import AppHeading from '@app/components/ui/AppHeading.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'

const { t } = useI18n()
const route = useRoute()
const {
  setErrors,
  values,
  errors,
} = useVVForm()

const formSubmitting = ref(false)
const success = ref(false)
const credential = route.query.credential

const invalid = computed(() => (
  Object.keys(errors.value).length > 0
))

function handleSubmit() {
  if (!formSubmitting.value) {
    formSubmitting.value = true

    sendPasswordResetLink({
      data: {
        attributes: values,
      },
    })
      .then(() => {
        success.value = true
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          setErrors({ ...e.response.data.errors })
        }
      })
      .finally(() => {
        formSubmitting.value = false
      })
  }
}
</script>
