<template>
  <form-group
    name="locale_selector"
    type="select"
    :initial-value="initialValue"
    :label="false"
    :hide-errors="hideErrors"
    :submittable="false"
    :form-control-props="{
      deselectable: false,
      options: options,
      searchable: false,
      openDirection: openDirection,
    }"
    @changed="handleChange"
  />
</template>

<script setup>
import FormGroup from '@shared/components/form/FormGroup.vue'

defineProps({
  // Form group's initial value
  initialValue: {
    type: String,
    default: null,
  },
  // Locale options available
  options: {
    type: Array,
    default: () => ([]),
  },
  // Hide errors
  hideErrors: {
    type: Boolean,
    default: false,
  },
  // Open direction
  openDirection: {
    type: String,
    default: 'bottom',
    validator(value) {
      // The value must match one of these strings
      return [
        'top',
        'bottom',
      ].includes(value)
    },
  },
})

const emits = defineEmits([
  'changed',
])

function handleChange(value) {
  emits('changed', value)
}
</script>
