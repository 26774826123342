<template>
  <p
    :class="subText ? '' : 'mb-8'"
    class="font-bold block w-fit mx-auto text-lg text-center"
  >
    {{ text ?? t('common.missing_title') }}
  </p>

  <p
    v-if="subText"
    class="font-bold block w-fit mx-auto mb-8 text-md text-center"
  >
    {{ subText }}
  </p>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

defineProps({
  // Display title text
  text: {
    type: String,
    default: null,
  },
  // Display title sub-text
  subText: {
    type: String,
    default: null,
  },
})
</script>
