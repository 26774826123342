<template>
  <auth-header />

  <auth-title
    v-if="useSocialite"
    :text="t('auth.messages.login_title')"
    :sub-text="t('auth.messages.login_subtitle')"
  />

  <auth-title
    v-else
    :text="t('auth.login.use_email')"
  />

  <socialite-form
    v-show="useSocialite"
    :providers="providers"
    :query="route.query"
  />

  <login-form
    v-show="!useSocialite"
    with-password-forgotten
    with-credential-verification-not-received
    :additional-errors="formErrors"
    :submitting="formSubmitting"
    @submitted="onSubmit"
  />

  <div
    class="mx-4 mb-4 grid grid-cols-1 gap-2 justify-around"
  >
    <auth-button
      url="#"
      :force-label="toggleButtonLabel"
      path="/"
      name="email"
      @click="toggleLoginType"
    />

    <auth-button
      url="/auth/register"
      :query="route.query"
      :force-label="t('auth.messages.register_mail')"
      path="/"
      name="email"
    />
  </div>

  <app-separator
    class="my-6"
  />
</template>

<script setup>
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import useLogin from '@shared/hooks/auth/login'
import useSocialiteProviders from '@shared/hooks/socialiteProviders'
import AppSeparator from '@shared/components/ui/AppSeparator.vue'
import AuthButton from '@app/components/auth/AuthButton.vue'
import AuthHeader from '@app/components/auth/AuthHeader.vue'
import AuthTitle from '@app/components/auth/AuthTitle.vue'
import LoginForm from '@app/components/auth/LoginForm.vue'
import SocialiteForm from '@app/components/pages/auth/Socialite/SocialiteForm.vue'

const { t } = useI18n()
const { providers } = useSocialiteProviders()
const route = useRoute()

/**
 * Socialite toggler ref
 */
const useSocialite = ref(true)

/**
 * Compute label text relative to login type
 */
const toggleButtonLabel = computed(() => {
  if (useSocialite.value) {
    return t('auth.login.use_email')
  }

  return t('auth.login.use_social_networks')
})

/**
 * Toggle login type
 */
function toggleLoginType() {
  useSocialite.value = !useSocialite.value
}

async function onSubmit(attributes) {
  handleSubmit({
    ...attributes,
    ...{
      user: route.query.u,
      excursion: route.query.e,
      poi: route.query.p,
      staying_hotel: route.query.staying_hotel,
    },
  })
}

const {
  formSubmitting,
  formErrors,
  handleSubmit,
} = useLogin()
</script>
