import providerData from '@shared/config/socialiteProviders.json'

export default function useSocialiteProviders() {
  /**
   * Merge options in main provider object
   *
   * @param {Object} element
   * @returns
   */
  function compact(element) {
    return {
      ...element,
      ...{
        url: `${import.meta.env.VITE_APP_BASE_URL}/social/${element.name}`,
      },
    }
  }

  const providers = providerData.map((element) => compact(element))
    .filter((element) => element.enabled)

  return {
    providers,
  }
}
