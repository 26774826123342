import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import * as api from '@shared/http/api'

export default function useLogin(options = {}) {
  const { t } = useI18n()
  const store = useStore()

  const formSubmitting = ref(false)
  const formErrors = ref({})

  function handleSubmit(attributes) {
    if (!formSubmitting.value) {
      formSubmitting.value = true

      api.login(attributes).then(async () => {
        // Loading is needed to hide the login transition
        // Otherwise, when logged, there is a short moment
        // when login form is shown with the full authenticated layout (navbar etc.)
        if (!options.noLoadingAfterLogin) {
          store.commit('loading/SET_TEXT', t('auth.login.logging_in'))
          store.commit('loading/ENABLE')
        }

        await store.dispatch('auth/getAuthenticatedUser')

        if (!options.noRedirectAfterLogin) {
          await store.dispatch('auth/redirectAuthenticatedUser')
        }

        if (!options.noLoadingAfterLogin) {
          store.commit('loading/RESET')
        }
      })
        .catch((e) => {
          if (e.response?.data?.errors) {
            formErrors.value = e.response.data.errors
          }
        })
        .finally(() => {
          formSubmitting.value = false
        })
    }
  }

  return {
    formSubmitting,
    formErrors,
    handleSubmit,
  }
}
