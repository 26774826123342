<template>
  <ul class="text-xs">
    <form-password-hint-item
      v-for="(item, i) in items"
      :key="i"
      :validated="item.validated"
      :text="item.text"
    />
  </ul>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import FormPasswordHintItem from '@shared/components/form/password_hint/FormPasswordHintItem.vue'

const props = defineProps({
  // Password to validate
  password: {
    type: String,
    default: '',
  },
  // Override items validator
  externalItems: {
    type: Array,
    default: () => [],
    required: false,
  },
})

const { t } = useI18n()

const items = computed(() => ([
  ...props.externalItems,
  {
    validated: uppercaseValidated.value,
    text: t('form.hints.profile.password_template.uppercase'),
  },
  {
    validated: lowercaseValidated.value,
    text: t('form.hints.profile.password_template.lowercase'),
  },
  {
    validated: digitValidated.value,
    text: t('form.hints.profile.password_template.digit'),
  },
  {
    validated: lengthValidated.value,
    text: t('form.hints.profile.password_template.length', { length: 8 }),
  },
]))

// Regex used are the same as server-side validation
const lengthValidated = computed(() => (
  props.password.length >= 8
))

const uppercaseValidated = computed(() => (
  props.password.match(/\p{Lu}/u) !== null
))

const lowercaseValidated = computed(() => (
  props.password.match(/\p{Ll}/u) !== null
))

const digitValidated = computed(() => (
  props.password.match(/\p{N}/u) !== null
))
</script>
